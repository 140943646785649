
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
































































.faq-grid {
  position: relative;
  grid-template-columns: 1fr 2fr;

  @include mq(m) {
    display: grid;
  }
}

.faq-grid__categories {
  @include mq($until: m) {
    padding-bottom: 7rem;
  }
}

.faq-grid__items__title,
.faq-grid__categories__title {
  @extend %text-uppercase;

  margin-bottom: $spacing;
  font-size: 1.3rem;

  @include mq($until: m) {
    margin-bottom: math.div($spacing, 2);
  }
}

.faq-grid__categories__items {
  @extend %list-nostyle;

  position: relative;
  z-index: layer(high);
  max-width: 30rem;
  margin-right: 3rem;
  border-radius: 5px;

  &.is-open {
    z-index: 99;
  }

  @include mq($until: m) {
    position: absolute;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-width: 100%;
    height: 4.5rem;
    background-color: $c-white;
    border: 1px solid $c-gray-light;
    cursor: pointer;

    &:not(.is-open) {
      * {
        pointer-events: none;
      }
    }
  }
}

.faq-grid__categories__item__icon {
  margin-left: $spacing;
  opacity: 0;
  transition: opacity 0.2s ease-out;

  @include mq($until: m) {
    transform: scale(0.6);
  }
}

.faq-grid__categories__item {
  position: relative;
  z-index: 10;
  left: math.div(-$spacing, 4);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 math.div($spacing, 2);
  padding: math.div($spacing, 2) $spacing;
  font-family: $ff-default;
  font-size: 1.5rem;
  font-weight: 400;
  border-bottom: 1px solid $c-gray-light;
  transition: 0.2s ease-out;
  transition-property: border, color;
  list-style: none;
  cursor: pointer;

  &:first-child {
    border-top: 1px solid $c-gray-light;
  }

  &:hover {
    color: $c-mustard;
  }

  & + & {
    /* stylelint-disable-next-line declaration-no-important */
    margin-top: -1px !important;
  }

  &.is-active {
    position: relative;
    z-index: 99;
    left: 0;
    margin: 0;
    font-weight: 700;
    border: 1px solid $c-mustard;
    border-radius: 5px;

    .faq-grid__categories__item__icon {
      opacity: 1;
    }

    @include mq($until: m) {
      order: -1;
      border: none;
    }
  }

  @include mq($until: m) {
    background-color: $c-white;
  }
}

.faq-grid__slides {
  position: relative;
}

.faq-grid__slide {
  // position: absolute;
  z-index: 1;
  display: none;
  width: 100%;
  opacity: 0;

  &.is-active {
    z-index: 3;
    display: block;
    opacity: 1;
  }
}
