
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












.faq-section__grid {
  @include default-margin(0.5);
}
